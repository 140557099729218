.FGAstyle {
  margin-top: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 100px;
}

.FGAstyle h2 {
  text-align: center;
  font-size: 34px;
  margin-bottom: 10px;
  color: #020202;
  font-weight: 600;
  font-style: normal;
  margin-bottom: 50px;
}

.faq-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 800px;
  margin: auto;
  padding: 20px;
  font-family: "Arial", sans-serif;
  gap: 20px; /* This adds space between the boxes */
}

.faq-item {
  width: 700px;
  background: #f9f9f9;
  border: 1px solid #ddd;
  flex: 1 1 100%; /* Initially take full width */
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px; /* Space between items vertically if they don’t wrap */
}

.faq-question {
  padding: 15px 20px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  color: #333;
}

.faq-answer {
  padding: 15px 20px;
  color: #666;
  line-height: 1.5;
  border-top: 1px solid #ddd;
  background: #fff;
}

.faq-icon {
  font-size: 24px;
  transition: transform 0.2s ease-in-out;
}

.faq-item.open .faq-icon {
  transform: rotate(45deg);
}

/* Responsive layout changes */
@media (max-width: 750px) {
  .faq-item {
    width: 500px;
  }
}

/* Responsive layout changes */
@media (max-width: 550px) {
  .faq-item {
    width: 350px;
  }
}

/* Responsive layout changes */
@media (max-width: 400px) {
  .faq-item {
    width: 300px;
  }
}

/* Responsive layout changes */
@media (min-width: 600px) {
  .faq-item {
    /* flex: 1 1 48%; Adjust this value as needed, around 50% to have two items per row */
  }
}
