.aboutUsContainer {
  font-family: "Arial", sans-serif;
  color: #333;
  line-height: 1.6;
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  margin-top: 100px;
}
.AboutUsHeader {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 600px;
}

.AUTitleIntro {
  width: 20%;
  padding-left: 5%;
}

.AUtitle h2 {
  font-size: 2.5rem;
  margin-bottom: 10px;
  color: #000000;
}

.AUtitle p {
  font-size: 1.125rem;
  line-height: 1.6;
  color: #000000;
}

.AUimg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.title {
  margin-top: 150px;
  color: #2a2a2a;
  text-align: center;
  margin-bottom: 20px;
}

.OurStoryParagraph {
  color: #2a2a2a;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 18px;
  line-height: 1.6;
  font-weight: 600;
  text-align: justify;
  margin: 20px 0;
  padding: 20px;
}

.underLineImg {
  height: 200px;
  width: 100%;
  background-image: url("../../../Images/AboutUs/backgroundOurStory.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.underLine {
  margin-top: 100px;
  margin-bottom: 100px;
  height: 3px;
  border-radius: 2.5rem;
  margin-left: 50px;
  margin-right: 50px;
  background-color: #0056b3;
  box-shadow: 0px 0px 1px 0px #0056b3;
}
.section {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 50px;
  gap: 20px;
  margin-top: 200px;
}

.founderSection {
  flex: 1;
  max-width: 50%;
}

.FounderImg {
  height: 500px;
  width: 400px;
}

.heading {
  font-size: 24px;
  margin-bottom: 20px;
}

.paragraph {
  font-size: 17px;
  line-height: 1.6;
  text-align: justify;
}
.AUmissionPromisesTitles {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 150px;
}
.AUmissionPromisesDesc {
  border: 2px solid #0056b3;
  box-shadow: 0px 0px 5px 0px #000000;
  margin: 20px;
  padding: 20px;
  border-radius: 2.5rem;
}

.AUmissionPromisesDesc h3 {
  color: #000000;
  font-size: 24px;
  text-align: center;
}

.AUmissionPromisesDesc p {
  font-size: 16px;
  color: #000000;
  margin: 15px 0;
}

.AUmissionPromises {
  margin: 0 auto;
  background: white;
  padding: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

/* Section styling */
.missionSection,
.promiseSection {
  border-left: 5px solid #0056b3;
  margin: 20px 0;
  padding: 20px;
  background-color: #eef3fa;
}

/* Headings */
.missionSection h3,
.promiseSection h3 {
  color: #0056b3;
  font-size: 24px;
}

/* Paragraphs */
.missionSection p,
.promiseSection p {
  font-size: 16px;
  color: #666;
  margin: 15px 0;
}

/* Responsive Design */
@media (max-width: 768px) {
  .AUmissionPromises {
    padding: 10px;
  }

  .missionSection,
  .promiseSection {
    padding: 15px;
  }

  .AUmissionPromisesDesc h3,
  .missionSection h3,
  .promiseSection h3 {
    font-size: 20px;
  }

  .AUmissionPromisesDesc p,
  .missionSection p,
  .promiseSection p {
    font-size: 14px;
  }
}

@media (max-width: 768px) {
  .section {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .founderSection {
    max-width: 100%;
  }

  .AUmissionPromisesTitles {
    flex-direction: column;
  }
}
@media (max-width: 450px) {
  .FounderImg {
    height: 450px;
    width: 350px;
  }
}

@media (max-width: 1000px) {
  .AUTitleIntro {
    width: 35%;
    padding-left: 5%;
    background-color: white;
    border-radius: 2rem;
  }
}
/* Responsive Design */
@media (max-width: 768px) {
  .aboutUsContainer {
    padding: 10px;
  }
  .AUTitleIntro {
    width: 80%;

    border-radius: 2rem;
  }
}
