@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap");

.HomePage-business-impact-strip {
  padding: 60px 20px;
  background: #f7f9fc;
  font-family: "Poppins", sans-serif;
  color: #333;
  padding-top: 150px;
}

.HomePage-container {
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
}

/* Section 1: Heading and three small boxes */
.HomePage-section-header {
  margin-bottom: 40px;
  text-align: center;
}

.HomePage-section-heading {
  font-size: 2.8rem;
  color: #011483;
  margin-bottom: 10px;
  font-weight: 700;
  text-transform: uppercase;
}

.HomePage-section-subheading {
  font-size: 1.2rem;
  color: #555;
  font-style: italic;
  margin: 0 auto;
  max-width: 600px;
}

.HomePage-small-boxes {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: wrap;
  margin-bottom: 100px;
}

.HomePage-small-box {
  flex: 1;
  min-width: 280px;
  background: linear-gradient(135deg, #e0efff, #ffffff);
  border: 1px solid #264bc4;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-align: left;
}

.HomePage-small-box:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.HomePage-small-box-title {
  font-size: 1.5rem;
  color: #011483;
  margin-bottom: 10px;
}

.HomePage-small-box-description {
  font-size: 1rem;
  color: #555;
  line-height: 1.5;
}

/* Section 2 & 3: Two-Column Layouts */
.HomePage-two-column-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
  margin-bottom: 100px;
  flex-wrap: wrap;
}

.HomePage-two-column-section.reverse {
  flex-direction: row-reverse;
}

.HomePage-text-column {
  flex: 1;
  min-width: 300px;
  text-align: left;
}

.HomePage-section-title {
  font-size: 2rem;
  color: #011483;
  margin-bottom: 15px;
}

.HomePage-section-description {
  font-size: 1.2rem;
  color: #333;
  line-height: 1.5;
}

/* Big Box: Styling for the large benefit boxes in Sections 2 & 3 */
.HomePage-big-box {
  flex: 1;
  min-width: 300px;
  background: linear-gradient(135deg, #cfe8ff, #eaf4ff);
  border: 2px solid #264bc4;
  border-radius: 10px;
  padding: 30px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-align: left;
}

.HomePage-big-box:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.15);
}

.HomePage-big-box-title {
  font-size: 1.8rem;
  color: #011483;
  margin-bottom: 15px;
}

.HomePage-big-box-description {
  font-size: 1.1rem;
  color: #555;
  line-height: 1.5;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .HomePage-small-boxes {
    flex-direction: column;
    align-items: center;
  }
  .HomePage-two-column-section {
    flex-direction: column;
    text-align: center;
  }
  .HomePage-text-column,
  .HomePage-big-box {
    text-align: center;
  }
}
