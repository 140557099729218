@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap");

.homepage-hero {
  position: relative;
  width: 100%;
  /* height: 100vh;  */
  background: #f7f9fc;
  font-family: "Poppins", sans-serif;
  overflow: hidden;
}

.hero-overlay {
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.97);
  /* background: rgba(136, 134, 134, 0.97); */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.hero-content {
  display: flex;
  width: 94%;
  align-items: flex-start;
  justify-content: center;
  gap: 40px;
  margin-top: 300px;
  margin-bottom: 200px;
}

/* Left column for the title */
.hero-left {
  flex: 1;
  text-align: left;
  height: 100%;
}

/* Right column for the description and buttons */
.hero-right {
  flex: 1;
  text-align: left;
}

/* Title style */
.hero-title {
  font-size: 3rem;
  color: #011483;
  margin-bottom: 20px;
}

/* Description style */
.hero-description {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 30px;
  line-height: 1.5;
}

/* Buttons container */
.hero-buttons {
  display: flex;
  gap: 20px;
}

/* Button styles */
.cta-button {
  padding: 15px 30px;
  border-radius: 5px;
  text-decoration: none;
  font-weight: 600;
  font-size: 1.2rem;
  transition: all 0.3s ease;
}

.cta-button.primary {
  background-color: #264bc4;
  color: #fff;
  border: none;
}

.cta-button.primary:hover {
  background-color: #3b82f6;
}

.cta-button.secondary {
  background-color: transparent;
  border: 2px solid #264bc4;
  color: #264bc4;
}

.cta-button.secondary:hover {
  background-color: #264bc4;
  color: #fff;
}

/* Feature Train Container - no background added here */
.feature-train-container {
  width: 100%;
  overflow: hidden;
  padding-top: 30px;
}

.feature-train {
  display: flex;
  /* JavaScript handles the continuous scrolling */
}

.feature-boxes {
  display: flex;
  gap: 15px;
}

.feature-box {
  display: flex;
  width: 650px;
  height: 400px;
  background: linear-gradient(
    135deg,
    #e0efff,
    #ffffff
  ); /* Blue-toned linear gradient */
  border: 1px solid #264bc4;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.first-feature-box {
  margin-left: 15px;
}

.feature-box:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.feature-box-left {
  width: 40%;
  height: 100%;
}

.feature-box-right {
  width: 60%;
  height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
}

.feature-image {
  width: 100%;
  height: 100%;
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
  object-fit: cover;
}

.feature-title {
  font-size: 1.5rem;
  color: #011483;
  margin-bottom: 10px;
}

.feature-description {
  font-size: 1rem;
  color: #555;
}

/* Responsive Adjustments */
@media (max-width: 850px) {
  .hero-content {
    flex-direction: column;
    text-align: center;
  }
  .hero-left,
  .hero-right {
    flex: none;
  }
  .hero-title {
    font-size: 2.5rem;
  }
  .hero-description {
    font-size: 1.3rem;
  }
  .cta-button {
    font-size: 1rem;
    padding: 12px 24px;
  }
}
