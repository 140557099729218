.HomePage-section-title {
  display: inline-block;
  font-size: 1.2rem;
  font-weight: 600;
  color: #0073e6; /* Modern blue */
  text-decoration: none;
  padding: 10px 15px;
  border: 2px solid transparent;
  border-radius: 8px;
  transition: all 0.3s ease-in-out;
  position: relative;
}

.HomePage-section-title::after {
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  background-color: #0073e6;
  position: absolute;
  bottom: 0;
  left: 0;
  transform: scaleX(0);
  transition: transform 0.3s ease-in-out;
}

.HomePage-section-title:hover {
  color: white;
  background-color: #0073e6;
  border-radius: 8px;
  padding: 10px 18px;
}

.HomePage-section-title:hover::after {
  transform: scaleX(1);
}
