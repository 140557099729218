@import url("https://fonts.googleapis.com/css2?family=Poller+One&display=swap");

.features-container {
  position: relative;
  padding-top: 50px;
  min-height: 100vh;
  color: white;
  background: linear-gradient(
    160deg,
    #a3b1c6 20%,
    #254ca0 40%,
    #5374a7 60%,
    #113c8d 80%
  );
  background-size: 400% 400%;
  animation: GradientAnimate 30s ease infinite;
  overflow: hidden;
}

@keyframes GradientAnimate {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
.productFeatures {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-family: "Arial", sans-serif;
  margin-top: 50px;
}
.productFeaturesDetails {
  max-width: 60%;
  padding: 20px;
  display: flex;
  flex-direction: column;
}
.PFcontent {
  width: 40%;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
}

.productFeatureTitle {
  width: 100%;
  text-align: center;
  font-size: 56px;
  text-shadow: 5px 5px rgb(88, 76, 76);
  font-family: "Poller One", serif;
  font-weight: 400;
  font-style: normal;
}
.PFpartUser {
  display: grid;
  grid-template-columns: repeat(2, 500px); /* Two equal columns */
  gap: 15px; /* Fixed gap between items */
  max-width: 1200px; /* Max width to prevent excessive stretching on large screens */
  margin: 0 auto; /* Center the grid within its container */
  margin-top: 15px;
}

.PFquickBox {
  box-shadow: 3px 5px 20px 0px black;
  border-radius: 1.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #616b714b;
  padding: 15px;
  max-width: 500px;
  height: 150px;
  /* margin-bottom: 10px; */
}

.PFquickBox img {
  height: 100px;
  width: 100px;
  margin-right: 20px;
}

.quickBoxDescriptions {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.quickBoxDescriptions h3 {
  font-size: 22px;
  font-family: "Poller One", serif;
  font-weight: 400;
  font-style: normal;
  margin-bottom: 0;
}
.quickBoxDescriptions p {
  margin-top: 5px;
  font-size: 17px;
  font-family: "Times New Roman", Times, serif;
}
.addMarginBottom10 {
  margin-bottom: 10px;
}
.userManagement {
  width: 500px;
  height: 370px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-shadow: 3px 5px 20px 0px black;
  border-radius: 1.5rem;
  background-color: #616b714b;
}

.UMsides {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.UMsides img {
  width: 50px;
  height: 50px;
}
.UMmiddle {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.UMmiddleTitle {
  text-align: center;
}
.userManagementImg {
  height: 250px;
  width: 200px;
}

.ProductAnalytic {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  box-shadow: 3px 5px 20px 0px black;
  border-radius: 1.5rem;
  background-color: #616b714b;
  padding: 15px;
  max-width: 990px;
  height: 400px;
  text-align: center;
}
.pipesAnalytic {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  align-items: center;
}

.pipesAnalytic img {
  height: 200px;
  width: 200px;
}
.quickBoxSpecialStyle {
  width: 90%;
}
.quickBoxSpecialStyle h3 {
  width: 100%;
}
.BusinessProcess {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  box-shadow: 3px 5px 20px 0px black;
  border-radius: 1.5rem;
  background-color: #616b714b;
  padding: 15px;
  max-width: 990px;
  text-align: center;
}

.BusinessProcessImg {
  height: 250px;
  width: 800px;
}
.AdditionalBoxesImg {
  height: 200px;
  width: 600px;
}

.featuresContentUserAdmin {
  box-shadow: 3px 5px 20px 0px black;
  border-radius: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: #616b718a;
  margin-top: 50px;
  width: 100%;
}

.PFcontentTitles {
  width: 100%;
  text-align: center;
  font-size: 26px;
  text-shadow: 3px 3px rgb(88, 76, 76);
  font-family: "Poller One", serif;
  font-weight: 400;
  font-style: normal;
}
/* Reset default styling for lists */
.main-list,
.nested-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
}

/* Styling for the main list items */
.main-list-item {
  font-family: "Arial", sans-serif;
  font-size: 18px;
  padding: 12px 20px;
  margin-bottom: 5px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, transform 0.3s ease;
}

/* Hover effect for the main list items */
.main-list-item:hover {
  background-color: #e0e0e0;
  transform: translateY(-2px);
  color: black;
}

/* Styling for nested lists */
.nested-list {
  padding-left: 20px;
  margin-top: 5px;
}

/* Styling for nested list items */
.nested-list-item {
  border-left: 4px solid #007bff;
  margin-bottom: 3px;
  padding: 8px 15px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

/* Hover effect for nested list items */
.nested-list-item:hover {
  background-color: #f0f8ff;
  color: black;
}

/* Additional styling for deeper nested lists */
.nested-list .nested-list {
  padding-left: 15px;
  margin-top: 3px;
}

/* Styling for third level nested list items */
.nested-list .nested-list-item {
  border-left: 3px solid #0062cc;
  padding: 6px 12px;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

/* Hover effect for third level nested list items */
.nested-list .nested-list-item:hover {
  background-color: #d9ecff;
  color: black;
}

@media (max-width: 1900px) {
  .BusinessProcess {
    max-width: 890px;
  }
  .PFpartUser {
    grid-template-columns: repeat(2, 450px);
  }
  .ProductAnalytic {
    max-width: 890px;
  }
  .userManagement {
    width: 440px;
  }
}

@media (max-width: 1600px) {
  .BusinessProcess {
    max-width: 800px;
  }
  .PFpartUser {
    grid-template-columns: repeat(1, 800px);
  }
  .ProductAnalytic {
    max-width: 800px;
  }
  .userManagement {
    width: 830px;
  }
  .PFquickBox {
    max-width: 100%;
    width: 830px;
  }
}

@media (max-width: 1600px) {
  .BusinessProcess,
  .ProductAnalytic,
  .userManagement,
  .PFquickBox {
    width: 700px;
  }
  .BusinessProcessImg {
    height: 200px;
    width: 650px;
  }
  .AdditionalBoxesImg {
    height: 200px;
    width: 500px;
  }
}

@media (max-width: 1350px) {
  .productFeaturesDetails {
    max-width: 50%;
  }
  .PFcontent {
    width: 45%;
  }
  .BusinessProcess,
  .ProductAnalytic,
  .PFquickBox {
    width: 600px;
  }
  .userManagement {
    width: 630px;
  }
  .BusinessProcessImg {
    height: 200px;
    width: 650px;
  }
  .AdditionalBoxesImg {
    height: 200px;
    width: 500px;
  }
  .pipesAnalytic img {
    height: 150px;
    width: 150px;
  }
}

@media (max-width: 1250px) {
  .productFeaturesDetails {
    max-width: 50%;
  }
  .PFcontent {
    width: 45%;
  }
  .BusinessProcess,
  .ProductAnalytic,
  .PFquickBox {
    width: 550px;
  }
  .BusinessProcessImg {
    height: 200px;
    width: 550px;
  }
  .userManagement {
    width: 580px;
  }
}

@media (max-width: 1150px) {
  .productFeaturesDetails {
    max-width: 50%;
  }
  .PFcontent {
    width: 45%;
  }
  .BusinessProcess,
  .ProductAnalytic,
  .PFquickBox {
    width: 450px;
  }
  .userManagement {
    width: 480px;
  }
  .BusinessProcessImg {
    height: 200px;
    width: 450px;
  }
  .AdditionalBoxesImg {
    height: 200px;
    width: 400px;
  }
  .pipesAnalytic img {
    height: 100px;
    width: 100px;
  }
}

@media (max-width: 970px) {
  .productFeatures {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .PFcontent {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    width: 95%;
    margin: 0;
    padding: 0;
    justify-content: center;
    align-items: center;
  }
  .productFeaturesDetails {
    max-width: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    justify-content: center;
    align-items: center;
  }
  .BusinessProcess {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    width: 90%;
    margin-bottom: 5px;
  }
  .PFpartUser {
    gap: 0; /* Fixed gap between items */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    width: 90%;
    margin: 0;
    padding: 20px;
  }
  .ProductAnalytic {
    padding: 15px;
    max-width: 90%;
    width: 100%;
    margin-top: -15px;
  }
  .userManagement {
    width: 100%;
    padding: 15px;
    margin-bottom: 20px;
  }
  .PFquickBox {
    width: 100%;
    margin-bottom: 20px;
  }
  .PFAdmin {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
  }
  .extraMarginTop {
    margin-top: -15px;
  }
  .BusinessProcessImg {
    width: 70%;
  }
}

@media (max-width: 550px) {
  .BusinessProcess {
    height: auto;
  }
  .PFpartUser {
    height: auto;
  }
  .ProductAnalytic {
    height: auto;
  }
  .userManagement {
    height: auto;
  }
  .PFquickBox {
    height: auto;
  }

  .PFAdmin {
    width: 100%;
  }
  .BusinessProcessImg {
    width: 90%;
  }
  .AdditionalBoxesImg {
    width: 90%;
  }
  .pipesAnalytic img {
    width: 30%;
  }
}
