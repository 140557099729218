.app-container {
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  color: #333;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.app-title {
  font-size: 2em;
  margin-bottom: 20px;
  color: #2c3e50;
  text-align: center;
}

.app-section {
  margin-bottom: 20px;
}

.section-intro,
.section-conclusion {
  font-size: 1.1em;
  line-height: 1.6;
  color: #555;
  margin-bottom: 15px;
}

.benefits-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.benefits-list li {
  font-size: 1em;
  line-height: 1.8;
  color: #666;
  margin-bottom: 10px;
  background-color: #fff;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.benefits-list li strong {
  color: #16a085;
}
