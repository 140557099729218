@keyframes gradientBackground {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.FT-lighting-effect {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 100%;

  background: linear-gradient(
    270deg,
    rgba(32, 54, 252, 0.5),
    rgba(9, 33, 112, 0.4),
    rgba(16, 124, 248, 0.5)
  );
  background-size: 200% 200%;
  animation: gradientBackground 10s ease infinite;
  display: block;
  z-index: 0;
}

.FreeTrial {
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url("../../../Images/FreeTrial/freeTrial.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  animation: animation-top 1s ease-out both;
}

@keyframes animation-top {
  0% {
    transform: scale(0.5) translateY(-80%);
    transform-origin: 50% 16%; /* Starts zoom from slightly above the center */
  }
  100% {
    transform: scale(1) translateY(0);
    transform-origin: top; /* Ensures that the zooming pivots around the top */
  }
}

.StepsToSchedule {
  z-index: 5;
}

/* Form Style */
.FreeTrialForm {
  background-color: rgb(255, 255, 255);
  max-width: 500px;
  margin: 40px auto;
  padding: 40px;
  border-radius: 2rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: left;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  width: 500px;
  color: #444444;
}
.FTFormTitle {
  font-size: 42px;
  margin-bottom: 1px;
}
.FTFormParagraph {
  font-size: 18px;

  margin-bottom: 50px;
}

.FTGroupOf2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 15px;
}

.FTrightSide {
  display: flex;
  flex-direction: column;
  width: 95%;
  margin-right: 2.5%;
}
.FTleftSide {
  display: flex;
  flex-direction: column;
  margin-left: 2.5%;
  width: 95%;
}

.FreeTrialForm input {
  padding: 10px;
  border: none;
  box-shadow: 0px 0px 4px 0px black;
  border-radius: 0.5rem;
  margin-top: 5px;
  width: 90%;
}
.FreeTrialForm textarea {
  padding: 10px;
  border: none;
  box-shadow: 0px 0px 4px 0px black;
  border-radius: 0.5rem;
  margin-top: 5px;
  min-height: 100px;
  width: 90%;
}
.FreeTrialForm select {
  padding: 10px;
  border: none;
  box-shadow: 0px 0px 4px 0px black;
  border-radius: 0.5rem;
  margin-top: 5px;
  background-color: white;
  width: 95%;
}

.formLabel {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  margin-top: 25px;
  width: 100%;
}

.calendar-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.react-calendar {
  border-radius: 1rem;
  padding: 15px;
  font-size: 20px;
}
.CalendarButtons {
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

/* animation */
.transition-enter {
  opacity: 0;
  transform: translateX(100%);
}

.transition-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}

.transition-exit {
  opacity: 1;
  transform: translateX(0);
}

.transition-exit-active {
  opacity: 0;
  transform: translateX(-100%);
  transition: opacity 300ms, transform 300ms;
}

@media (max-width: 650px) {
  .FreeTrialForm {
    width: 400px;
  }
}

@media (max-width: 500px) {
  .FreeTrialForm {
    width: 70%;
    height: auto;
    margin-top: 150px;
  }
  .FTGroupOf2 {
    flex-direction: column;
    height: auto;
  }
  .FTrightSide {
    width: 100%;
    margin-right: 0%;
  }
  .FTleftSide {
    margin-left: 0%;
    width: 100%;
    margin-top: 15px;
  }
}
