@import url("https://fonts.googleapis.com/css2?family=Poller+One&display=swap");

@keyframes gradientBackground {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
.PricingChoosing {
  position: relative;
  width: 100%;
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../../../Images/Pricing/header.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.lighting-effect {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 100%;

  background: linear-gradient(
    270deg,
    rgba(6, 18, 124, 0.5),
    rgba(67, 188, 202, 0.3),
    rgba(33, 95, 165, 0.5)
  );
  background-size: 200% 200%;
  animation: gradientBackground 15s ease infinite;
  display: block;
}

.PricingCallToAction {
  text-align: center;
  z-index: 5;
}

.PricingCallToAction h1 {
  font-size: 44px;
  margin-bottom: 10px;
  color: #ffffff;
  text-shadow: 2px 1px 4px black;
  font-family: "Poller One", serif;
  font-weight: 900;
  font-style: normal;
}

.PricingCallToAction p {
  font-size: 18px;
  color: #ffffff;
  text-shadow: 2px 1px 4px black;
  font-family: "Poller One", serif;
  font-weight: 400;
  font-style: normal;
  margin-bottom: 20px;
}

.PricingCallToAction a {
  display: inline-block;
  background-color: #ffffff;
  color: #000000;
  font-size: 17px;
  font-family: "Poller One", serif;
  font-weight: 400;
  font-style: normal;
  padding: 12px 24px;
  border-radius: 2.5rem;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.PricingCallToAction a:hover {
  background-color: #e9e9e9;
}

.PricingPackages {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: 100px;
}
.PPtitle {
  font-size: 34px;
  margin-bottom: 10px;
  color: #020202;
  font-weight: 600;
  font-style: normal;
  text-align: center;
}
.PPparagraph {
  font-size: 18px;
  margin-bottom: 10px;
  color: #020202;
  /* font-weight: 600; */
  font-style: normal;
  margin-bottom: 100px;
  text-align: center;
  padding-left: 40px;
  padding-right: 40px;
  min-width: 500px;
}
.PPdetails {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  font-family: "Arial", sans-serif;
  color: #333;
  width: 100%;
}

.PPdetails h1 {
  font-size: 32px;
  color: #222;
  text-align: center;
}

.package {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 25px;
  transition: transform 0.3s ease;
  width: 25%;
}

.package:hover {
  transform: translateY(-5px);
}

.package h2 {
  font-size: 26px;
  color: #007bff;
  border-bottom: 3px solid #007bff;
  padding-bottom: 12px;
  margin-bottom: 20px;
}

.features h3 {
  font-size: 22px;
  color: #444;
  margin-bottom: 15px;
}

.features ul {
  list-style-type: none;
  padding: 0;
}

.features ul li {
  font-size: 16px;
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
}

.features ul li:last-child {
  border-bottom: none;
}

.features ul li::before {
  content: "•";
  color: #007bff;
  font-weight: bold;
  display: inline-block;
  width: 20px;
  margin-left: -20px;
}

.support,
.additional-services,
.why-remove-add {
  margin-top: 30px;
}

.support h3,
.additional-services h3,
.why-remove-add h3 {
  font-size: 22px;
  color: #444;
  margin-bottom: 15px;
}

.support ul,
.additional-services ul,
.why-remove-add ul {
  list-style-type: none;
  padding: 0;
}

.support ul li,
.additional-services ul li,
.why-remove-add ul li {
  font-size: 16px;
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
}

.support ul li:last-child,
.additional-services ul li:last-child,
.why-remove-add ul li:last-child {
  border-bottom: none;
}

@media (max-width: 1000px) {
  .PPdetails {
    flex-direction: column;
    display: flex;
    align-items: center;
    width: 100%;
  }
  .package {
    width: 80%;
    margin-bottom: 100px;
  }
  .PPparagraph {
    min-width: 70%;
  }
}

@media (max-width: 400px) {
  .PricingCallToAction h1 {
    font-size: 36px;
  }
}
