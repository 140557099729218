@keyframes gradientBackground {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.restaurant-app-benefits {
  background-color: #1a73e8;
  padding: 30px;
  border-radius: 8px;
  max-width: 100%;
  color: #fff;
  margin-top: 200px;
  background: linear-gradient(270deg, #87b8f8, #3c70b4, #002b64);
  background-size: 200% 200%;
  animation: gradientBackground 15s ease infinite;
}

.restaurant-app-benefits h2 {
  font-size: 28px;
  text-align: center;
  margin-bottom: 80px;
}

.benefit-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.benefit-item {
  background-color: #ffffff;
  color: #333;
  /* width: 25%; */
  flex: 0 1 calc(40% - 20px);
  margin-bottom: 20px;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s ease, background-color 0.3s ease;
  min-height: 350px;
}

.benefit-item:hover {
  transform: translateY(-5px);
  background-color: #f1f1f1;
}

.benefit-item a {
  color: #1a73e8;
  text-decoration: none;
}

.benefit-image {
  width: 200px;
  height: 200px;
  height: auto;
  border-radius: 6px;
  margin-bottom: 10px;
}

.benefit-item h3 {
  font-size: 18px;
  margin: 10px 0;
}

.benefit-item p {
  font-size: 16px;
  line-height: 1.5;
  margin: 0;
}
