.info-strip {
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  border-radius: 8px;
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.1); /* Light shadow for depth */
  margin: 20px;
  margin-top: 60px;
}

.info-block h3 {
  font-size: 28px;
  margin-bottom: 20px;
  max-width: 600px;
  margin-bottom: 50px;
  color: #333; /* Dark text color */
}

.info-statistics {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.statistic {
  background-color: #ffffff; /* White background for stats */
  border: 1px solid #e0e0e0; /* Light border for separation */
  border-radius: 8px;
  padding: 20px;
  width: 200px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05); /* Slight shadow for cards */
}

.statistic h2 {
  font-size: 28px;
  margin-bottom: 10px;
  color: #0056b3; /* Highlight color for numbers */
}

.statistic p {
  font-size: 16px;
  color: #555; /* Dark grey text color */
}
