@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.ContactUsContainer {
  /* Full screen setup */
  width: 100%; /* Full width of the viewport */
  display: flex;
  flex-direction: row;
  align-items: center; /* Center content vertically */
  justify-content: space-around; /* Center content horizontally */
  text-align: center;

  /* Styling */
  padding-top: 150px;

  /* More complex and abstract blue gradient background */
  background-image: url("../../../Images/ContactUs/headerBackground.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.WhyUs {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 400px;

  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
  text-align: left;
}
.WhyUs h1 {
  font-size: 36px;
  margin: 0;
}
.WhyUs p {
  font-size: 16px;
  color: #575757;
}
.WhyUsPoints {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 20px;
}

.WhyUsPoints img {
  height: 70px;
  width: 70px;
  border-radius: 50%;
  margin-top: 10px;
  margin-bottom: 5px;
}

.WhyUsPoints h3 {
  font-size: 18px;
  margin-top: 0;
  margin-bottom: 0;
}

.WhyUsPoints p {
  margin-top: 0;
  margin-bottom: 0;
}

/* Form Container */
.form {
  background-color: rgb(255, 255, 255);
  max-width: 500px;
  margin: 40px auto;
  padding: 40px;
  border-radius: 2rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: left;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  color: #444444;
}
.FormTitle {
  font-size: 42px;
  margin-bottom: 1px;
}
.FormParagraph {
  font-size: 18px;

  margin-bottom: 50px;
}

.GroupOf2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 15px;
}

.rightSide {
  display: flex;
  flex-direction: column;
  width: 95%;
  margin-right: 2.5%;
}
.leftSide {
  display: flex;
  flex-direction: column;
  margin-left: 2.5%;
  width: 95%;
}

.form input {
  padding: 10px;
  border: none;
  box-shadow: 0px 0px 4px 0px black;
  border-radius: 0.5rem;
  margin-top: 5px;
}
.form textarea {
  padding: 10px;
  border: none;
  box-shadow: 0px 0px 4px 0px black;
  border-radius: 0.5rem;
  margin-top: 5px;
  min-height: 200px;
}
.form select {
  padding: 10px;
  border: none;
  box-shadow: 0px 0px 4px 0px black;
  border-radius: 0.5rem;
  margin-top: 5px;
  background-color: white;
}
.formLabel {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  margin-top: 25px;
  width: 100%;
}

@media (max-width: 1000px) {
  .ContactUsContainer {
    flex-direction: column-reverse;
    padding-bottom: 100px;
  }
  .form {
    max-width: 100%;
    width: 70%;
  }
}

@media (max-width: 600px) {
  .GroupOf2 {
    flex-direction: column;
  }
  .rightSide {
    width: 100%;
    margin-right: 0%;
  }
  .leftSide {
    margin-left: 0%;
    width: 100%;
    margin-top: 15px;
  }

  .WhyUs {
    width: 80%;
  }
}
